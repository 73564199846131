<template>

  <template v-if="isBootstrapDataLoading">
    <div class="loading-icon-container">
      <LoadingIcon />
    </div>
  </template>

  <template v-else>

    <form @submit="submit">
      <LoadingOverlay type="light" v-if="form.isLoading" />
      <FormInput :formName="formName" fieldName="recipients" ref="focusElement" />
      <FormInput :formName="formName" fieldName="templateId" />
      <FormInput :formName="formName" fieldName="templateParams" />
      <button type="submit" :disabled="form.isLoading || !isFormValid">Submit</button>
    </form>

  </template>

</template>

<script>

  import { mapState } from 'vuex'

  import useForm from '@/composables/useForm'

  import FormInput from '@/components/forms/FormInput.vue'

  import LoadingIcon from '@/components/utils/LoadingIcon.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'

  export default {
    components: {
      FormInput,
      LoadingIcon,
      LoadingOverlay,
    },
    setup() {

      // data
      const formName = 'adminMailerForm'

      const { form, isFormValid, focusElement } = useForm({ formName })

      return {
        form,
        formName,
        isFormValid,
        focusElement,
      }

    },
    computed: {
      ...mapState('app', ['isBootstrapDataLoading']),
      numRecipients() {
        const recipients = this.form.fields.recipients.value
        if (!recipients) return 0
        return recipients.split(',').filter((recipient) => { return recipient }).length
      },
    },
    methods: {
      submit($event) {
        if ($event) $event.preventDefault()
        return this.$store.dispatch('forms/SUBMIT_FORM', this.formName)
          .then(() => {
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: `Email${this.numRecipients === 1 ? '' : 's'} sent!`,
              type: 'success',
            })
          })
          .catch((error) => {
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: `Could not send email${this.numRecipients === 1 ? '' : 's'}: ${error.message}`,
              type: 'error',
            })
          })
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .loading-icon-container
    @apply my-16
    @apply flex
    @apply justify-center

  form
    @apply relative

</style>
